<template>
  <nav class="nav">
    <ul class="list-items">
      <li v-for="(item, index) in menuList" :key="index" 
        :class="routeName.toLowerCase() == (item.title.eng || item.title.default).toLowerCase()
        ? 'active'
        : ''" 
        @click="MenuSelect(index)"
         class="menu-list-item">
        <p style="display: flex; gap: 0.4rem;" class="cursor-pointer slow-underline" v-if="!item.isRequiredLogin || (item.isRequiredLogin && subscriberid)">
          <!-- <img :src="item.icon" :alt="item.title.default" style="height: 20px; width: 20px;" /> -->
          <MenuIcons :item='item.title.default.toUpperCase()'
            :active="routeName.toLowerCase() == (item.title.eng || item.title.default).toLowerCase() ? true : false" class="menuIcons"/>
          <span class="itemTitle">{{ menuTitleShow(item.title, localDisplayLangCode) }}</span>
        </p>
      </li>
    </ul>
  </nav>
</template>
<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import Utility from "@/mixins/Utility.js";

export default {
  name: "NavBar",
  data() {
    return {
      menuList: [],      
      subscriberProfileDetails: null,
      localDisplayLangCode: null
    }
  },
  created() {
    this.getMenuList();
    this.localDisplayLangCode = localStorage.getItem("setDisplayLanguageCode");
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLangCode = data;
    });
  },
  mounted() {
    this.localDisplayLangCode = localStorage.getItem("setDisplayLanguageCode");
  },
  methods: {
    getMenuList() {
      let menuItemsList = [];
       if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
          // this.filterMenuItemsingDecking();
          let dataset = localStorage.getItem("appDeckingConfig") ? JSON.parse(localStorage.getItem("appDeckingConfig")) : '';
          menuItemsList = dataset.screens;

      } else {
        menuItemsList = this.appConfig.screens;
      }
      let currentProfileDetails = localStorage.getItem(
        "subscriberProfileDetails"
      );

      if (currentProfileDetails) {
        currentProfileDetails = JSON.parse(currentProfileDetails);
        this.subscriberProfileDetails = currentProfileDetails;
      }

      const menuListArr = [];
      menuItemsList.forEach((menu) => {
        if (
          this.subscriberid && menu.screenPosition == "TOP" && 
          this.subscriberProfileDetails &&
          this.subscriberProfileDetails.kidsmode === "YES"
        ) {
          if (menu.profileTypes && (menu.profileTypes.includes("KIDS") || menu.profileTypes.includes("ALL"))) {
            menuListArr.push(menu);
            // this.mobileMenuList.push(menu)
          }
        } else if (
            menu.profileTypes && menu.screenPosition == "TOP" &&
            (menu.profileTypes.includes("ADULT") ||
              menu.profileTypes.includes("MASTER") || menu.profileTypes.includes("ALL"))
          ) {
            menuListArr.push(menu);
            // this.mobileMenuList.push(menu);
          }
        else if (menu.profileTypes && menu.screenPosition == "MORE" &&
            (menu.profileTypes.includes("ADULT") ||
              menu.profileTypes.includes("MASTER") || menu.profileTypes.includes("ALL"))) {
                this.itemList.push(menu); 
                // this.mobileMenuList.push(menu);
              } 
      });
      this.menuList = menuListArr;
    },
    
    menuTitleShow (title, language) {
      if(language == "eng" || "en") {
        return title.eng || title.default.toLowerCase();
      } else if(language == "es") {
        return title.spa || title.default.toLowerCase();
      } else if(language == "fr") {
        return title.fre || title.default.toLowerCase();
      } else if(language == "ml") {
        return title.mal || title.default.toLowerCase();
      }
    },

    MenuSelect (index) {
      // if (this.isLanguageSwitchEnabled == true) {
      //   this.isLanguageSwitchEnabled = false;
      // }
      let currentLanguage = this.getCurrentLanguageUrlBase();
      let moreMenuDetail = this.menuList[index]
       let sectionTitle = moreMenuDetail.title.eng.replaceAll(' ','').toLowerCase();
      //  this.hideList = false;

       if (sectionTitle == 'home') {
        this.$router.push({
            name: "Home",
            params: { section: 'home', lang: currentLanguage },
          });
       }  else if(sectionTitle == 'epg'){
          this.$router.push({
              name: "Epg",
              params: { moreMenuDetail, lang: currentLanguage },
            });
       } else {
          this.$router.push({
              name: "section-screen",
              params: { section: sectionTitle, lang: currentLanguage },
            });
       }   
       if (screen.width <= 768 ) {
        this.toggleMobileMenu();
        }
 
    },
  },
  components: {

  },
  computed: {
    ...mapGetters([
      "appConfig",
      "subscriberid",
      "getRtl",
      "subscriptionList",
      "isMovieEnabled",
      "profileid",
      "getPlanDeeplinkPath",
    ]),
    
    routeName() {
      let tempVar = this.$route.path;
      tempVar = tempVar.toLowerCase();
      if (tempVar.includes("movie")) {
        return "movies";
      }
      else if (tempVar.includes("channel")) {
        return "channels";
      } 
      else if (tempVar.includes("livetv")) {
        return "Live Tv";
      } 
      else if (tempVar.includes("tvshow")) {
          return "TV Show";
        } 
       else if (tempVar.includes("shows") || tempVar.includes("/series")) {
        return "tv shows";
      } else if (tempVar.includes("songs") || tempVar.includes("music") || tempVar.includes('-video-songs')) {
        return "music";
      } else if (tempVar.includes("kids")) {
        return "kids";
      } else if (tempVar.includes("webseries")) {
        return "webseries";
      } else if (tempVar.includes("shorts")) {
        return "shorts";
      }  else if (tempVar.includes("watchlist")) {
        return "watchlist";
      } else if (tempVar.includes("devotional")) {
        return "devotional";
      } else if (tempVar.includes("viewAllPlans")) {
        return "plan";
      } else if (tempVar.includes("search")) {
        //search home
        return "search";
      } else if (tempVar.includes("epg")) {
        //search home
        return "epg";
      } else {
        return "home";
      }
    },
},
components: {
  MenuIcons: () => import(/* webpackChunkName: "menuicon" */ "@/components/SvgImages/MenuIconsList.vue" ),
},
mixins: [Utility],
}
</script>
<style scoped lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_utilities.scss";
@import "./Navbar.scss";
</style>